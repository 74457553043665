import React from "react";
import { Link, graphql } from "gatsby";
import Header from "../components/header";
import voorstelling from "../images/voorstellingen-fr.png";
import media from "../images/media.png";
import circus from "../images/geschiedenis-fr.png";
import tour from "../images/tour-tickets copy.png";
import contact from "../images/contact.png";
import "../styles/styles.css";
var slugify = require("../helpers");

const IndexPage = ({ data }) => {
  const mediaLink = () => {
    const url = "/media/" + slugify(data.allMysqlAlbums.edges[0].node.title_fr);
    return (
      <Link to={url}>
        <img className="homeImage media" src={media} alt="media" />
      </Link>
    );
  };
  return (
    <div>
      <Header back="false" lang taal="fr" />
      <Link to="/fr/spectacles">
        <img className="homeImage" src={voorstelling} alt="voorstellingen" />
      </Link>
      {mediaLink()}
      <Link to="/fr/circus_ou_theatre">
        <img
          className="homeImage circus"
          src={circus}
          alt="Circus of Theater"
        />
      </Link>
      <Link to="/fr/tour_et_tickets">
        <img className="homeImage tour" src={tour} alt="Tour et Tickets" />
      </Link>
      <Link to="/fr/contact">
        <img className="homeImage contact" src={contact} alt="contact" />
      </Link>
    </div>
  );
};
export default IndexPage;
export const query = graphql`
  query {
    allMysqlAlbums {
      edges {
        node {
          id
          title_fr
        }
      }
    }
  }
`;
